<template>
  <div>
    <Toolbar class="p-mb-4">
      <template #left>
        <Button
          label="اضافة جديد"
          icon="pi pi-plus"
          v-tooltip="'اضافه جديد'"
          class="p-ml-2 p-button-success"
          v-if="$checkRoles('tasksAdd')"
          @click="$router.push('tasks/add')"
        />
        <Button
          label="حذف"
          icon="pi pi-trash"
          v-tooltip="'حذف'"
          @click="deleteAll"
          v-if="$checkRoles('tasksDelete')"
          :disabled="selectedItems.length == 0"
          class="p-button-danger"
        />
      </template>

      <template #right>
        <Button
          icon="pi pi-download"
          class="p-button-help"
          @click="exportCSV($event)"
          v-tooltip="'طباعه'"
        />
      </template>
    </Toolbar>

    <DataTable
      ref="dt"
      @page="onPage($event)"
      @sort="onSort($event)"
      :value="list"
      :totalRecords="totalRecords"
      :paginator="true"
      class="p-datatable-customers table table-striped"
      :rows="10"
      dataKey="id"
      :lazy="true"
      :rowHover="true"
      :loading="loading"
      :filters="filters"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 25, 50, 100,500,1000,10000]"
      currentPageReportTemplate="عرض {first} الى {last} من {totalRecords} مدخلات"
    >
      <template #header>
        <div class="table-header">
          قائمه متابعة العملاء المستهدفين
        </div>
      </template>
      <template #empty>
        لا يوجد بيانات
      </template>
      <template #loading>
        يتم تحميل البيانات. يرجي الانتظار...
      </template>
      <Column selectionMode="multiple" field="id" headerStyle="width: 3em" />
      <Column
        field="code"
        header="الكود"
        filterMatchMode="contains"
        :sortable="true"
      >
        <template #filter>
          <InputText
            type="number"
            @change="onFilter($event, 'code')"
            class="p-column-filter"
            placeholder="بحث بالكود"
          />
        </template>
      </Column>
      <Column
        field="date"
        header="تاريخ المهمه"
        filterMatchMode="contains"
        :sortable="true"
      >
        <template #filter>
          <InputText
            type="date"
            @change="onFilter($event, 'date')"
            class="p-column-filter"
            placeholder="بحث بتاريخ المهمه"
          />
        </template>
      </Column>
      <Column
        field="usersId.name"
        header="مسئول المبيعات"
        :sortable="true"
        v-if="$checkRoles('tasksAll')"
      >
        <template #filter>
          <select
            class="form-select form-control p-column-filter"
            id="usersId"
            name="usersId"
            @change="onFilter($event, 'usersId')"
            placeholder="بحث بمسئول المبيعات"
          >
            <option :value="null">الكل </option>
            <option v-for="item of usersList" :key="item.id" :value="item.id">{{
              item.name
            }}</option>
          </select>
        </template>
      </Column>
      <Column
        field="clientName"
        header="العميل"
        filterMatchMode="contains"
        :sortable="true"
      >
        <template #filter>
          <InputText
            @change="onFilter($event, 'clientName')"
            class="p-column-filter"
            placeholder="بحث باسم العميل"
          />
        </template>
      </Column>
      <Column
        field="who"
        header="النشاط"
        filterMatchMode="contains"
        :sortable="true"
      >
        <template #filter>
          <InputText
            @change="onFilter($event, 'who')"
            class="p-column-filter"
            placeholder="بحث باسم النشاط"
          />
        </template>
      </Column>

      <Column field="status" header="الحاله" :sortable="true">
        <template #filter>
          <select
            class="form-select form-control p-column-filter"
            id="status"
            name="status"
            @change="onFilter($event, 'status')"
            placeholder="بحث بالحاله"
          >
            <option :value="null">الكل </option>
            <option value="1">غير مهتم</option>
            <option value="2">اهتمام بسيط</option>
            <option value="3">مهتم</option>
            <option value="4">مهتم جدا</option>
          </select>
        </template>
        <template #body="slotProps">
          {{ slotProps.data.status == 1 ? 'غير مهتم' : '' }}
          {{ slotProps.data.status == 2 ? 'اهتمام بسيط' : '' }}
          {{ slotProps.data.status == 3 ? 'مهتم' : '' }}
          {{ slotProps.data.status == 4 ? 'مهتم جدا' : '' }}
        </template>
      </Column>
      <Column field="citiesId.name" header="المدينه" :sortable="true">
        <template #filter>
          <select
            class="form-select form-control p-column-filter"
            id="citiesId"
            name="citiesId"
            @change="onFilter($event, 'citiesId')"
            placeholder="بحث بالمدينه"
          >
            <option :value="null">الكل </option>
            <option
              v-for="item of citiesList"
              :key="item.id"
              :value="item.id"
              >{{ item.name }}</option
            >
          </select>
        </template>
      </Column>
      <Column
        field="managerName"
        header="المسئول"
        filterMatchMode="contains"
        :sortable="true"
      >
        <template #filter>
          <InputText
            @change="onFilter($event, 'managerName')"
            class="p-column-filter"
            placeholder="بحث باسم المسئول"
          />
        </template>
      </Column>
      <Column
        field="managerPhone"
        filterMatchMode="contains"
        header="جول المسئول"
        :sortable="true"
      >
        <template #filter>
          <InputText
            @change="onFilter($event, 'managerPhone')"
            class="p-column-filter"
            placeholder="بحث بجول المسئول"
          />
        </template>
      </Column>
      <Column
        field="phone"
        header="هاتف"
        filterMatchMode="contains"
        :sortable="true"
      >
        <template #filter>
          <InputText
            @change="onFilter($event, 'phone')"
            class="p-column-filter"
            placeholder="بحث بالهاتف"
          />
        </template>
      </Column>

      <Column field="id" bodyStyle="text-align: center; overflow: visible">
        <template #body="slotProps">
          <Button
            type="button"
            @click="$router.push('tasks/edit/' + slotProps.data.id)"
            icon="pi pi-pencil"
            v-tooltip="'تعديل'"
            v-if="$checkRoles('tasksEdit')"
            class="p-button-success p-ml-2  p-button-rounded"
          ></Button>
          <Button
            type="button"
            icon="pi pi-trash"
            v-tooltip="'حذف'"
            v-if="$checkRoles('tasksDelete')"
            @click="deleteItem(slotProps.data.id)"
            class="p-button-warning  p-button-rounded"
          ></Button>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      selectedItems: [],
      loading: true,
      filters: {},
      citiesList: [],
      usersList: [],
      limit: 10,
      page: 0,
      totalRecords: 0,
      body: {},
    };
  },
  methods: {
    onPage(event) {
      console.log(event);
      this.limit = event.rows;
      this.page = event.page;
      this.getData();
    },
    onSort(event) {
      this.body.order = {};
      this.body.order[event.sortField] = event.sortOrder;
      this.getData();
    },
    onFilter(event, name) {
      if (event.target.value) {
        this.body.where = {};

        this.body.where[name] = event.target.value;
      } else {
        this.body.where = {};
      }
      this.getData();
    },
    getData() {
      if (this.$checkRoles('tasksAll')) {
        this.$http
          .post(
            `tasks/searchByPaginate?page=${this.page + 1}&limit=${this.limit}`,
            this.body,
          )
          .then(
            (response) => {
              this.loading = false;
              this.list = response.data.items;

              this.totalRecords = response.data.count;
              console.log(this.totalRecords, 'totalRecords');
            },
            (err) => {
              this.loading = false;
              this.$toast.add({
                severity: 'error',
                summary: 'هناك خطأ',
                detail: err.response.data.message,
                life: 3000,
              });
            },
          );
      } else {
        const user = JSON.parse(localStorage.admin);
        if (this.body.where) {
          this.body.where['usersId'] = user.id;
        } else {
          this.body.where = {};
          this.body.where['usersId'] = user.id;
        }

        this.$http
          .post(
            `tasks/searchByPaginate?page=${this.page + 1}&limit=${this.limit}`,
            this.body,
          )
          .then(
            (response) => {
              this.loading = false;
              this.list = response.data.items;

              this.totalRecords = response.data.count;
            },
            (err) => {
              this.loading = false;
              this.$toast.add({
                severity: 'error',
                summary: 'هناك خطأ',
                detail: err.response.data.message,
                life: 3000,
              });
            },
          );
      }
    },

    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    deleteAll() {
      this.$confirm.require({
        message: 'هل انتا متاكد انك تريد حذف السجلات',
        header: 'تأكيد الحذف',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$http
            .delete(`tasks/deleteList`, {
              idList: this.selectedItems,
            })
            .then(
              () => {
                this.getData();
                this.$toast.add({
                  severity: 'error',
                  summary: 'تم بنجاح',
                  detail: 'تم الحذف بنجاح',
                  life: 3000,
                });
              },
              (err) => {
                this.$toast.add({
                  severity: 'error',
                  summary: 'هناك خطأ',
                  detail: err.response.data.message,
                  life: 3000,
                });
              },
            );
        },
      });
    },
    deleteItem(id) {
      this.$confirm.require({
        message: 'هل انتا متاكد انك تريد حذف السجلات',
        header: 'تأكيد الحذف',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$http.delete(`tasks/${id}`).then(
            () => {
              this.getData();
              this.$toast.add({
                severity: 'error',
                summary: 'تم بنجاح',
                detail: 'تم الحذف بنجاح',
                life: 3000,
              });
            },
            (err) => {
              this.$toast.add({
                severity: 'error',
                summary: 'هناك خطأ',
                detail: err.response.data.message,
                life: 3000,
              });
            },
          );
        },
      });
    },
  },
  created() {
    if (!this.$checkRoles('tasksVeiw')) {
      this.$router.push('/admin/');
    }
    this.$http.get(`cities`).then((res) => {
      this.citiesList = res.data;
    });
    this.$http.get(`users`).then((res) => {
      this.usersList = res.data;
    });
    this.getData();
  },
};
</script>

<style>
.col-form-label {
  text-align: start;
}
</style>
